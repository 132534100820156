import React from "react";
import { Link as RouterLink } from "react-router-dom";
import "./CtaSection.css";

const CtaSection = () => {
  return (
    <>
      <div className="Cta-container">
        <p className="Cta-text">Like what you see? Try Labelions !</p>
        {/* button  */}
        <RouterLink to="/contactUs" className="contact-button-router">
          <div className="contact-button">
            <p className="contact-button-text">Talk to an Expert</p>
          </div>
        </RouterLink>
      </div>
    </>
  );
};

export default CtaSection;
