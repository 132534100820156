import React from "react";
import "./Process.css";
import docIcon from "../../assets/images/docIcon.svg";
import docAddIcon from "../../assets/images/docAddIcon.svg";
import checkIcon from "../../assets/images/checkIcon.svg";

const Process = () => {
  return (
    <>
      <div className="process-container">
        <div className="process-content">
          <p className="process-text">Our Process</p>
          <div className="process-right-section">
            <p className="process-vertical-line"></p>

            <div className="circular-container">
              <div className="circular-ellipse">
                <p className="process-number">1</p>
              </div>

              <div className="circular-ellipse">
                <p className="process-number">2</p>
              </div>

              <div className="circular-ellipse">
                <p className="process-number">3</p>
              </div>
            </div>

            <div>
              <div className="process-rectangle">
                <img
                  className="process-rectangle-icon"
                  src={docAddIcon}
                  alt="Onboarding"
                />
                <div className="process-rectangle-rightText">
                  <div className="process-rectangle-title-mobContainer">
                    <img
                      className="process-rectangle-icon-mobile"
                      src={docAddIcon}
                      alt="Onboarding"
                    />
                    <p className="process-rectangle-rightText-Title">
                      Onboarding
                    </p>
                  </div>

                  <p className="process-rectangle-rightText-InfoText">
                    Get on a 15 minute call with our team to discuss
                    requirements, timeline and budget
                  </p>
                </div>
              </div>
              <div className="process-rectangle">
                <img
                  className="process-rectangle-icon"
                  src={docIcon}
                  alt="Signing SOW"
                />
                <div className="process-rectangle-rightText">
                  <div className="process-rectangle-title-mobContainer">
                    <img
                      className="process-rectangle-icon-mobile"
                      src={docIcon}
                      alt="Signing SOW"
                    />
                    <p className="process-rectangle-rightText-Title">
                      Signing SOW
                    </p>
                  </div>

                  <p className="process-rectangle-rightText-InfoText">
                    A simple 2 page SOW is signed to start the contract
                  </p>
                </div>
              </div>
              <div className="process-rectangle">
                <img
                  className="process-rectangle-icon"
                  src={checkIcon}
                  alt="HandOver"
                />
                <div className="process-rectangle-rightText">
                  <div className="process-rectangle-title-mobContainer">
                    <img
                      className="process-rectangle-icon-mobile"
                      src={checkIcon}
                      alt="HandOver"
                    />
                    <p className="process-rectangle-rightText-Title">
                      Handover
                    </p>
                  </div>
                  <p className="process-rectangle-rightText-InfoText">
                    After our quality team verifies the data. You receive the
                    final files to close the project
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Process;
