import React from "react";
import HeroSection from "../HeroSection/HeroSection";
import IndustryBanner from "../IndustryBanner/IndustryBanner";
import Services from "../Services/Services";
import Process from "../Process/Process";
import Prices from "../Prices/Prices";
import Footer from "../Footer/Footer";
import CtaSection from "../CtaSection/CtaSection";

const Home = () => {
  return (
    <>
      <HeroSection />
      <IndustryBanner />
      <Services />
      <Process />
      <Prices />
      <CtaSection />
      {/* <Footer /> */}
    </>
  );
};

export default Home;
