import React, { useEffect, useState } from "react";
import "./HeroSection.css";
import { Link } from "react-scroll";
import aiSample from "../../assets/images/aiSample.png";
import aiSample1 from "../../assets/images/aiSample1.jpg";
import aiSample2 from "../../assets/images/aiSample2.jpg";
import aiSample3 from "../../assets/images/aiSample3.jpg";
import targetImage from "../../assets/images/targetImage.jpg";
import computerImage from "../../assets/images/computerImage.jpg";

import shield from "../../assets/images/shield.svg";
import label from "../../assets/images/label.svg";
import target from "../../assets/images/target.svg";

const HeroSection = () => {
  return (
    <section id="aboutus">
      <div className="hero-container">
        <div className="hero-content">
          <div className="hero-left-section">
            <div className="data-label-container">
              <p className="data-label">
                <span className="ai-text">AI</span>
                <span> Data labelling with</span>
              </p>
            </div>

            <div className="right-text">
              <div className="right-text-content">
                <p className="first-text">the best security</p>
                <img className="vector-icon1" alt="security" src={shield} />
              </div>
              <div className="right-text-content">
                <p className="second-text">the best prices</p>
                <img className="vector-icon2" alt="prices" src={label} />
              </div>
              <div className="right-text-content">
                <p className="third-text">the best accuracy</p>
                <img className="vector-icon3" alt="accuracy" src={target} />
              </div>
            </div>
          </div>
          <div className="image-fader">
            <img className="hero-img" alt="Ai Sample" src={aiSample} />
            <img className="hero-img" alt="Ai Sample" src={computerImage} />
            <img className="hero-img" alt="Ai Sample" src={targetImage} />
            <img className="hero-img" alt="Ai Sample" src={aiSample3} />
          </div>
        </div>

        {/* button  */}
        <Link to="prices" smooth={true} duration={500}>
          <div className="prices-button">
            <p className="prices-button-text">See Our Unreal Prices</p>
          </div>
        </Link>
      </div>
    </section>
  );
};

export default HeroSection;
