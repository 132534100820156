import React from "react";
import "./Prices.css";

const Prices = () => {
  return (
    <section id="prices">
      <div className="prices-container">
        <div className="prices-content">
          <p className="prices-text">Pricing</p>
          <div className="prices-content-right">
            <p className="prices-right-text">
              Flat <span className="price">$0.025</span> per Label for Simple
              Projects
            </p>
            <p className="prices-right-text">
              Flat <span className="price">$0.040</span> per Label for Complex
              Projects
            </p>
            <div className="table-container">
              <table className="custom-table">
                <thead>
                  <tr>
                    <th>Labelions</th>
                    <th>AWS</th>
                    <th>Labelbox</th>
                    <th>Clarifai</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="second-row">
                    <td className="second-row-first-col">$2000</td>
                    <td>$8000</td>
                    <td>$5000</td>
                    <td>$6000</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="table-container-small">
              <table className="custom-table-small">
                <thead>
                  <tr>
                    <th className="custom-table-small-leftcolumn">Labelions</th>
                    <th className="custom-table-small-rightCol-green">$2000</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="custom-table-small-leftcolumn">AWS</td>
                    <td className="custom-table-small-rightCol">$8000</td>
                  </tr>
                  <tr>
                    <th className="custom-table-small-leftcolumn">Labelbox</th>
                    <th className="custom-table-small-rightCol">$5000</th>
                  </tr>
                  <tr>
                    <td className="custom-table-small-leftcolumn">Clarifai</td>
                    <td className="custom-table-small-rightCol">$6000</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="terms-conditions">
              <p className="terms-conditions-text">
                * Pricing displayed for 100,000 labels. T&C Apply.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Prices;
