import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <p className="footer-text">
        Copyright © 2023 Dragua RSM Technologies Private Limited. All Rights
        Reserved. I Terms Of Use I Privacy Policy I EULA
      </p>
    </div>
  );
};

export default Footer;
