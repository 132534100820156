import React, { useRef } from "react";
import "./ContactUs.css";
import Footer from "../Footer/Footer";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = () => {
  const notify = () => toast("Successfully Submitted!");
  let myForm = useRef();

  const sendEmail = (formData) => {
    emailjs
      .sendForm(
        "service_0e32wqx",
        "template_r8lbuio",
        myForm,
        process.env.REACT_APP_PUB_KEY
      )
      .then((response) => {
        console.log("Email sent successfully:", response);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    sendEmail(data);
    notify();
  };

  return (
    <>
      <div className="contactUs-container">
        <p className="contactUs-text">Contact Us</p>
        <form
          ref={(form) => (myForm = form)}
          className="form-container"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            className="input-field"
            placeholder="Full Name*"
            {...register("fullName", {
              required: "Full Name is required",
              maxLength: 20,
            })}
            aria-invalid={errors.fullName ? "true" : "false"}
          />
          {errors.fullName && (
            <p className="error-alert" role="alert">
              {errors.fullName.message}
            </p>
          )}

          <input
            className="input-field"
            placeholder="Work Email*"
            {...register("mail", { required: "Email Address is required" })}
            aria-invalid={errors.mail ? "true" : "false"}
          />
          {errors.mail && (
            <p className="error-alert" role="alert">
              {errors.mail.message}
            </p>
          )}
          <p className="service-required">Service Required*</p>
          {/* radio button */}
          <div className="radio-option-container">
            <div>
              <label className="radio-option1">
                <input
                  className="radio-option-input"
                  type="radio"
                  {...register("radioOption")}
                  value="NLP"
                />
                <span>NLP</span>
              </label>
            </div>
            <div>
              <label className="radio-option2">
                <input
                  className="radio-option-input"
                  type="radio"
                  {...register("radioOption")}
                  value="computer-vision"
                />
                <span>Computer Vision</span>
              </label>
            </div>
            <div>
              <label className="radio-option3">
                <input
                  className="radio-option-input"
                  type="radio"
                  {...register("radioOption")}
                  value="data-collection"
                />
                <span>Data Collection</span>
              </label>
            </div>
          </div>

          <input
            className="input-field"
            placeholder="Approximate Units to be Labelled*"
            type="number"
            {...register("unitsToLabel", {
              required: "Approximate Units is required",
              // min: 1,
              // max: 99,
            })}
            aria-invalid={errors.unitsToLabel ? "true" : "false"}
          />
          {errors.unitsToLabel && (
            <p className="error-alert" role="alert">
              {errors.unitsToLabel.message}
            </p>
          )}
          <textarea
            className="message-field"
            placeholder="Message"
            {...register("message", { required: true, maxLength: 2000 })}
          ></textarea>

          <input className="submit-btn" type="submit" />
        </form>
        <ToastContainer />
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
