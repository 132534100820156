import React from "react";
import { Link as RouterLink } from "react-router-dom";
import NlpImage from "../../assets/images/NlpImage.png";
import sentimentAnalysis from "../../assets/images/sentimentAnalysis.png";
import IntentImage from "../../assets/images/IntentImage.png";
import audioImage from "../../assets/images/audioImage.png";
import namedEntityImage from "../../assets/images/namedEntityImage.png";
import computerVisionImage from "../../assets/images/computerVisionImage.png";
import imageclassificationImage from "../../assets/images/imageclassificationImage.png";
import polygonImage from "../../assets/images/polygonImage.png";
import tagIcon from "../../assets/images/tagIcon.svg";
import adsClickIcon from "../../assets/images/adsClickIcon.svg";
import schoolGradIcon from "../../assets/images/schoolGradIcon.svg";
import "./Services.css";

const Services = () => {
  return (
    <section id="services">
      <div className="services-container">
        <div className="services-content">
          <p className="services-text">Our Services</p>
          <div className="cards">
            <div className="flex-box">
              <RouterLink to="/contactUs" className="contact-button-router">
                <div className="card-box">
                  <img src={NlpImage} alt="" className="card-box-img" />
                  <p className="card-box-title">NLP Text Classification</p>
                  <p className="card-box-info">
                    Ask experts from Labelions to draw classify or categorize
                    entire texts with predefined category tags
                  </p>
                </div>
              </RouterLink>
              <RouterLink to="/contactUs" className="contact-button-router">
                <div className="card-box">
                  <img
                    src={sentimentAnalysis}
                    alt=""
                    className="card-box-img"
                  />
                  <p className="card-box-title">Sentiment Analysis</p>
                  <p className="card-box-info">
                    Ask experts from Labelions to label texts with sentiment
                    categories for any purpose, from understanding customer
                    reviews to spam filtering
                  </p>
                </div>
              </RouterLink>

              <RouterLink to="/contactUs" className="contact-button-router">
                <div className="card-box">
                  <img src={IntentImage} alt="" className="card-box-img" />
                  <p className="card-box-title">Intent Classification</p>
                  <p className="card-box-info">
                    Ask experts from Labelions to categorize user queries into
                    relevant predefined intents
                  </p>
                </div>
              </RouterLink>
              <RouterLink to="/contactUs" className="contact-button-router">
                <div className="card-box">
                  <img src={audioImage} alt="" className="card-box-img" />
                  <p className="card-box-title">Audio Classification</p>
                  <p className="card-box-info">
                    Create or fine-tune a voice interface using speech samples
                    recorded by Labelions performers according to your
                    instructions
                  </p>
                </div>
              </RouterLink>

              <RouterLink to="/contactUs" className="contact-button-router">
                <div className="card-box">
                  <img src={namedEntityImage} alt="" className="card-box-img" />
                  <p className="card-box-title">Named Entity Recognition</p>
                  <p className="card-box-info">
                    Ask experts from Labelions to identify parts of text,
                    classify proper nouns, or label any other entities
                  </p>
                </div>
              </RouterLink>
              <RouterLink to="/contactUs" className="contact-button-router">
                <div className="card-box">
                  <img
                    src={computerVisionImage}
                    alt=""
                    className="card-box-img"
                  />
                  <p className="card-box-title">Computer Vision:Bounding Box</p>
                  <p className="card-box-info">
                    Ask experts from Labelions to identify objects in images
                    that match certain classes and use boundaries boxes to mark
                    the location
                  </p>
                </div>
              </RouterLink>
              <RouterLink to="/contactUs" className="contact-button-router">
                <div className="card-box">
                  <img
                    src={imageclassificationImage}
                    alt=""
                    className="card-box-img"
                  />
                  <p className="card-box-title">Image Classification</p>
                  <p className="card-box-info">
                    Ask experts from Labelions to classify images by matching
                    visual content with one or more pre-defined categories
                  </p>
                </div>
              </RouterLink>
              <RouterLink to="/contactUs" className="contact-button-router">
                <div className="card-box">
                  <img src={polygonImage} alt="" className="card-box-img" />
                  <p className="card-box-title">Polygon Segmentation</p>
                  <p className="card-box-info">
                    Ask experts from Labelions to identify objects in images
                    that match certain classes and draw pixel-perfect polygons
                    around the exact shape
                  </p>
                </div>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>

      {/* bottom layout */}
      <div className="bottom-layout">
        <p className="bottom-layout-title">Why Choose Labelions</p>
        <div className="bottom-layout-infoContainer">
          <p className="bottom-layout-infoText">
            Labelions is built by a team of Ivy league academicians and AI
            professionals who’ve been building AI models for the past 18 years.
            So no matter what your requirements are, our team can deliver the
            highest quality work for the lowest prices with guaranteed accuracy
          </p>
        </div>

        <div className="bottom-layout-content">
          <div className="bottom-layout-items">
            <img src={tagIcon} alt="best price" />
            <p className="bottom-layout-contentTitle">
              Best Prices
              <br />
              in the Industry
            </p>
            <p className="bottom-layout-contentText">
              We offer the highest quality work for the lowest prices in the
              industry
            </p>

            {/* button */}
            <RouterLink to="/contactUs" className="button-router">
              <div className="learnMore-button">
                <p className="learnMore-button-text">Learn More ></p>
              </div>
            </RouterLink>
          </div>
          <div className="bottom-layout-items">
            <img src={adsClickIcon} alt="best price" />
            <p className="bottom-layout-contentTitle">
              95% Accuracy
              <br />
              or Nothing
            </p>
            <p className="bottom-layout-contentText">
              You do not pay until we deliver 95% or higher accuracy for all
              your projects
            </p>

            {/* button */}
            <RouterLink to="/contactUs" className="button-router">
              <div className="learnMore-button">
                <p className="learnMore-button-text">Learn More ></p>
              </div>
            </RouterLink>
          </div>
          <div className="bottom-layout-items">
            <img src={schoolGradIcon} alt="best price" />
            <p className="bottom-layout-contentTitle">
              PhD-led
              <br />
              Project Managers{" "}
            </p>
            <p className="bottom-layout-contentText">
              Our projects are managed by a team of AI professionals and Ivy
              league academicians
            </p>

            {/* button */}
            <RouterLink to="/contactUs" className="button-router">
              <div className="learnMore-button">
                <p className="learnMore-button-text">Learn More ></p>
              </div>
            </RouterLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
