import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "react-scroll";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import labelMeLogo from "../../assets/images/labelMeLogo.svg";
import "./Navbar.css";

const Navbar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <RouterLink to="/" className="navbar-logo">
            <img src={labelMeLogo} alt="" />
            <span className="label">Label</span>
            <span className="me">ions</span>
          </RouterLink>

          <div className="menu-section">
            <ul className="menu-items">
              <li className="nav-item">
                <Link
                  to="services"
                  smooth={true}
                  duration={500}
                  className="nav-links"
                >
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="prices"
                  smooth={true}
                  duration={500}
                  className="nav-links"
                >
                  Pricing
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="aboutus"
                  smooth={true}
                  duration={500}
                  className="nav-links"
                >
                  About Us
                </Link>
              </li>

              <li className="nav-item">
                <RouterLink to="/contactUs" className="nav-links">
                  Contact Us
                </RouterLink>
              </li>
            </ul>
            {/* button */}
            <RouterLink to="/contactUs" className="button-router">
              <div className="nav-button">
                <p className="nav-button-text">Talk to an Expert</p>
              </div>
            </RouterLink>
          </div>

          <div className="menu-button" onClick={toggleSidebar}>
            {!showSidebar ? (
              <AiOutlineMenu size={20} />
            ) : (
              <AiOutlineClose size={20} />
            )}
          </div>
        </div>
        {showSidebar && (
          <div className="menu-section-mobile">
            <ul className="menu-items-mobile">
              <li className="nav-item">
                <Link
                  to="services"
                  smooth={true}
                  duration={500}
                  className="nav-links"
                  onClick={toggleSidebar}
                >
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="prices"
                  smooth={true}
                  duration={500}
                  className="nav-links"
                  onClick={toggleSidebar}
                >
                  Pricing
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="aboutus"
                  smooth={true}
                  duration={500}
                  className="nav-links"
                  onClick={toggleSidebar}
                >
                  About Us
                </Link>
              </li>

              <li className="nav-item">
                <RouterLink
                  to="/contactUs"
                  className="nav-links"
                  onClick={toggleSidebar}
                >
                  Contact Us
                </RouterLink>
              </li>
            </ul>
            {/* button */}
            <RouterLink
              to="/contactUs"
              className="button-router"
              onClick={toggleSidebar}
            >
              <div className="nav-button">
                <p className="nav-button-text">Talk to an Expert</p>
              </div>
            </RouterLink>
          </div>
        )}
      </nav>
    </>
  );
};

export default Navbar;
