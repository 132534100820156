import React from "react";
import "./IndustryBanner.css";
import ibmLogo from "../../assets/images/ibmLogo.png";
import uclaLogo from "../../assets/images/uclaLogo.png";

const IndustryBanner = () => {
  return (
    <div className="industrybanner-container">
      <div>
        <p className="banner-text">
          Labelions is relied upon by MNCs, Startups and Universities across the
          globe for building world AI Models.
        </p>
      </div>
      <div className="banner-logo-container">
        <p className="banner-logo">FAANG</p>
        <p className="banner-logo">Scout Better</p>
        <img className="ibm-img" src={ibmLogo} alt="ibm" />
        <img className="ibm-img" src={uclaLogo} alt="UCLA University" />
      </div>
    </div>
  );
};

export default IndustryBanner;
